<template>
  <!-- 质押页面（合并挖矿，流动性，分红） -->
  <div class="pl-3 pr-3 mine-container" :class="{'mobie':isMobileBol}">
    <!-- 未解锁钱包 -->
    <v-card v-if="!$store.state.isLogin" style="background: #232429; width: 70%; margin: 0 auto">
      <v-container class="mt-0 mb-0 pt-0 pb-0 unlock-wallet-box">
        <v-container class="text-center font-weight-bold btnTextColor--text unlock">
          {{ $t("请先连接您的钱包") }}
        </v-container>
        <v-divider class="light-divider"></v-divider>
        <v-btn @click="handleUnlockWallet" class="mr-1 rounded-lg unlock-button" color="btnColor">
          <span class="btnTextColor--text">{{ $t("解锁钱包") }}</span>
        </v-btn>
      </v-container>
    </v-card>
    <!-- 已解锁钱包 -->
    <v-container v-else>
      <!-- 顶部header -->
      <div v-if="!isWrongNet">
        <div v-if="info.length < 1">
          <v-progress-linear indeterminate color="darkBlue darken-2"></v-progress-linear>
          <h3 class="white--text loading">{{ $t("加载中") }}.......</h3>
        </div>

        <!-- 新列表 pc -->
        <v-container fluid v-else-if ="!isMobileBol">
            <v-progress-linear v-if="info.length > 0 && info.length < settleToken.length" indeterminate
              color="darkBlue darken-2"></v-progress-linear>
              <h4  class="tag-ifno" style="font-size: 22px;color:#fff;width: 1300px;margin: 0 auto;margin-bottom:20px;padding-left: 8px;">
                <div class="lumps"><span></span><span></span></div>
                 <span style=" padding-left: 20px;">{{ $t('质押挖矿') }}</span>
                </h4>
              <div class="new-list-content"  v-for="(singleInfo,index) in info" :key="index" >
                <div class="content-left">
                  <div class="icon_image_content">
                    <v-img style="border-radius: 50%;" :src="require(`../assets/currencyType/${singleInfo.logoName}`)"
                      max-height="45" max-width="45">
                    </v-img>

                  </div>
                  <div class="trade-type" :class="{'charm-trade':singleInfo.name.split('/').length > 1}"
                    @click="charmOpen(singleInfo)">
                    {{ singleInfo.name }}
                  </div>
                </div>

                <div class="content-right">
                  <div class="liquidity-line" v-if="!singleInfo.isPowerswap&&singleInfo.name!=='CHARM'">
                    <div class="item-info">
                      <div>
                        <span>{{ $t('流动性占比') }}</span>
                        <span>                     {{
                        !singleInfo.LiquidityShareAccountedFor
                          ? "<0.01"
                          : singleInfo.LiquidityShareAccountedFor
                      }}%</span>
                      </div>
                      <div>
                        <span>{{ $t('持有流动性代币') }}</span>
                        <span> {{ singleInfo.liquidityNumber }}</span>
                      </div>
                      <div>
                        <span>{{ $t('流动性总量') }}</span>
                        <span>{{ singleInfo.liquidityTotal }}</span>
                      </div>
                      <div>
                        <span>{{ $t('总开仓量') }}</span>
                        <span> {{ singleInfo.openTotal }}</span>
                      </div>
                    </div>
                    <div class="operation" :class="type ? 'operation-active': ''">
                      <v-btn  style="background: #2C39EC;color:#fff;border-radius: 4px;margin-right:10px" small @click="handleAdd(singleInfo)">
                        <span class="text1">{{ $t("添加流动性") }}</span>
                      </v-btn>
                      <v-btn style="background:transparent; border: 1px solid#CA585A;color:#CA585A;border-radius: 4px;" small @click="handleClear(singleInfo)"> <span
                          class="text1">{{ $t("移除流动性") }}</span>
                      </v-btn>
                    </div>
                  </div>
                  <div class="mine-line " :class="type ? 'line-active': ''">
                    <div class="item-info">
                      <div>
                        <span>{{ $t('质押APR') }}</span>
                        <span> {{ singleInfo.apr + '%' }}</span>
                      </div>
                      <div>
                        <span>{{ $t('质押TVL') }}</span>
                        <span> {{ singleInfo.tvl }}</span>
                      </div>
                      <div>
                        <span>{{ $t('质押收益') }}</span>
                        <span> {{ singleInfo.reward }}/{{ $t("天") }}</span>
                      </div>
                      <v-btn style="background: #2C39EC; color:#fff;border-radius: 4px;" small
                        :loading="singleInfo.loading" @click="handleHarvest(singleInfo)"> <span class="text1">
                         {{  $t('质押获取') }}
                        </span>
                        <span>
                          {{ singleInfo.harvest }}
                        </span>
                      </v-btn>
                    </div>
                    <div class="operation">
                        <v-btn style="width:94px;color:#fff;background: #2C39EC;border-radius: 4px;margin-right:10px" small
                          @click="handleStakeButton(singleInfo)"> <span class="text1">{{ $t('添加质押') }}</span>
                        </v-btn>
                        <v-btn style="width:94px;background:transparent; border: 1px solid#CA585A;color:#CA585A;border-radius: 4px;" small
                          @click="handleUnstakeButton(singleInfo)"> <span class="text1">{{ $t('移除质押') }}</span>
                        </v-btn>
                    </div>
                  </div>


                </div>
              </div>
        </v-container>

         <!-- 旧列表(移动端) -->
        <v-container fluid v-else>
          <h4  class="tag-ifno" style="font-size: 22px;color:#fff;width: 1300px;margin: 0 auto;margin-bottom:20px;padding-left: 25px;">
                <div class="lumps"><span></span><span></span></div>
                 <span style=" padding-left: 20px;">{{ $t('质押挖矿') }}</span>
                </h4>
          <v-row style="justify-content: center;">
            <v-progress-linear v-if="info.length > 0 && info.length < settleToken.length" indeterminate
              color="darkBlue darken-2"></v-progress-linear>
            <v-card class="card_content" v-for="(singleInfo,index) in info" :key="index" :width="isMobileBol?290:250">

              <v-list dense subheader style="background: transparent;">
                <div class="icon_image_content">
                  <v-img style="border-radius: 50%;" :src="require(`../assets/currencyType/${singleInfo.logoName}`)"
                    max-height="50" max-width="50">
                  </v-img>

                </div>
                <div class="trade-type" :class="{'charm-trade':singleInfo.name.split('/').length > 1}"
                  @click="charmOpen(singleInfo)">
                  {{ singleInfo.name }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span style="margin-bottom: 8px;color: rgba(0, 0, 0, 0.87);" v-bind="attrs" v-on="on"
                        class="mdi mdi-help-circle-outline mdi-16px"></span>
                    </template>
                    <span>
                      {{
                      $t("先添加流动性")
                      }}</span>
                  </v-tooltip>
                </div>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">APR</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.apr + '%' }}
                  </v-list-item-icon>
                </v-list-item>
                <v-container class="card_footer">
                  <v-btn style="opacity: 0.9;background: #1e1e4b;border-radius: 16px;margin-right:10px" small
                    @click="handleStakeButton(singleInfo)"> <span class="text1">{{ $t("添加质押") }}</span>
                  </v-btn>
                  <v-btn style="opacity: 0.9;background: #bb4dba;border-radius: 16px;" small
                    @click="handleUnstakeButton(singleInfo)"> <span class="text1">{{ $t("取消质押") }}</span>
                  </v-btn>
                </v-container>

                <v-divider style="max-width: 88%;margin: 0 auto;"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">TVL</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.tvl }}
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('收益')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.reward }}/{{ $t("天") }}
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('可获取')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.harvest }}
                  </v-list-item-icon>
                </v-list-item>
              </v-list>

              <v-container class="card_footer">
                <v-btn style="opacity: 0.9;background: #bb4dba;border-radius: 16px;" small
                  :loading="singleInfo.loading" @click="handleHarvest(singleInfo)"> <span class="text1">{{ $t("获取")
                  }}</span>
                </v-btn>
              </v-container>

              <v-divider style="max-width: 88%;margin: 0 auto;"></v-divider>


              <div v-if="!singleInfo.isPowerswap&&singleInfo.name!=='CHARM'">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('持有流动性数量')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.liquidityNumber }}
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('流动性占比')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{
                      !singleInfo.LiquidityShareAccountedFor
                        ? "<0.01"
                        : singleInfo.LiquidityShareAccountedFor
                    }}%
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('预估对应结算币数量')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.pusdTotal }}
                  </v-list-item-icon>
                </v-list-item>

                <v-container class="card_footer">
                  <v-btn style="opacity: 0.9;background: #1e1e4b;border-radius: 16px;" small @click="handleAdd(singleInfo)">
                    <span class="text1">{{ $t("添加流动性") }}</span>
                  </v-btn>
                </v-container>

                <v-divider style="max-width: 88%;margin: 0 auto;"></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('流动性总量')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.liquidityTotal }}
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('结算币总量')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.settleTotal }}
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('总开仓量')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ singleInfo.openTotal }}
                  </v-list-item-icon>
                </v-list-item>
                <v-container class="card_footer">
                  <v-btn style="background: #bb4dba;border-radius: 16px;" small @click="handleClear(singleInfo)"> <span
                      class="text1">{{ $t("移除流动性") }}</span>
                  </v-btn>
                </v-container>
              </div>

            </v-card>
          </v-row>

        </v-container>

        <!-- 分红 -->
        <Divid></Divid>
      </div>
      <h4 v-else style="text-align: center; color: #fff">
        {{ $t("错误网络") }}
      </h4>
    </v-container>

    <!-- 抵押弹窗 -->
    <v-dialog v-model="isStakeShow" overlay-color="#202e8d" :width="dialogWidth">
      <v-card dark style="background: #202e8d">
        <v-container class="text-center font-weight-bold textColor--text text-h6">
          <span>{{ $t("质押") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isStakeShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>

        <v-alert border="bottom" color="purple" text type="error" v-show="(this.currentItem.name !='CHARM' && this.currentItem.name !='CNYT')">{{
        $t("需先提供流动性再质押") }}</v-alert>

        <v-container class="pl-3 pr-3 pb-5">
          <v-container class="mb-5" style=" ">
            <div class="d-flex align-center justify-space-between">
              <p class="mb-0 text-caption">
                {{ $t("未质押") }}:
                {{ this.currentItem.balance }}
              </p>
            </div>
            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between pt-9" style="height: 44px">
              <v-text-field class="pt-0" v-model="inputStake"></v-text-field>
              <v-subheader class="pl-0 pr-0" style="padding-bottom: 23px">
                <v-btn color="#424D5F" @click="handleStakeMax"><span class="bearButtonTrade--text">{{
                $t("最大")
                }}</span></v-btn>
              </v-subheader>
            </div>
          </v-container>
          <v-btn block :disabled="
            !inputStake || inputStake == '0' || isNaN(Number(inputStake))
          " :loading="isStakeLoading" v-show="!isStaked" @click="handleStakeVerify" class="rounded-lg mb-1"
            color="#224a6d"><span class="white--text"> {{ $t("批准") }}</span></v-btn>
          <v-btn block :loading="isStakedLoading" :disabled="!isStaked" @click="handleStake" class="rounded-lg"
            color="#224a6d"><span class="white--text"> {{ $t("确定") }}</span></v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 取消质押 -->
    <v-dialog v-model="isUnstakeShow" overlay-color="#202e8d" :width="dialogWidth">
      <v-card dark style="background: #202e8d">
        <v-container class="text-center font-weight-bold darkPink--text text-h6">
          <span> {{ $t("取消质押") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isUnstakeShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <v-container class="pl-3 pr-3 pb-5">
          <v-container class="mb-5" style=" ">
            <div class="d-flex align-center justify-space-between">
              <p class="mb-0 text-caption">
                {{ $t("已质押") }}:
                {{ this.currentItem.stake }}
              </p>
            </div>
            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between pt-9" style="height: 44px">
              <v-text-field class="pt-0" v-model="inputUnstake"></v-text-field>
              <v-subheader class="pl-0 pr-0" style="padding-bottom: 23px">
                <v-btn color="#4A2F4E" @click="handleUnstakeMax"><span class="darkPink--text"> {{ $t("最大") }}</span>
                </v-btn>
              </v-subheader>
            </div>
          </v-container>
          <v-btn block :disabled="
            !inputUnstake ||
            inputUnstake == '0' ||
            isNaN(Number(inputUnstake))
          " :loading="isUnstakeLoading" v-show="!isUnstaked" @click="handleUnstakeVerify" class="rounded-lg mb-1"
            color="#4A2F4E"><span class="white--text"> {{ $t("批准") }}</span></v-btn>
          <v-btn block :loading="unstakeLoading" :disabled="!isUnstaked" @click="handleUnstake" class="rounded-lg"
            color="#4A2F4E"><span class="white--text"> {{ $t("确定") }}</span></v-btn>
        </v-container>
      </v-card>
    </v-dialog>


    <!-- 添加流动性弹窗 -->
    <v-dialog v-model="addLiquiShow" overlay-color="#202e8d" :width="dialogWidth">
      <v-card dark style="background: #202e8d">
        <v-container class="text-center font-weight-bold text-h6">
          <span>{{ $t("添加流动性") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="addLiquiShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <v-container class="pl-3 pr-3 pb-5">
          <v-container class="mb-5" style="">
            <div class="d-flex align-center justify-space-between">
              <p class="mb-0 text-caption">
                {{ $t("余额") }}: {{ to4DecimalString(settleBalance) }}
              </p>
            </div>
            <div class="d-flex align-center justify-space-between pt-9" style="height: 44px">
              <v-text-field class="pt-0" v-model="inputLiquiAdd"></v-text-field>
              <v-subheader class="pl-0 pr-0" style="padding-bottom: 23px">
                <v-btn style="background: #3d5fe1;border-radius: 6px;" @click="handleAddMax"><span style="color:#fff">{{
                    $t("最大")
                  }}</span></v-btn>
              </v-subheader>
            </div>
          </v-container>
          <p style="font-size: 12px">
            {{ $t("预计获得流动性份额") }} {{ ` : ${inputLiquiAdd}` }}
          </p>
          <v-btn block :disabled="!inputLiquiAdd || inputLiquiAdd == '0' || isNaN(Number(inputLiquiAdd))" :loading="addVerifingLoading"
            v-show="!addVerified" @click="handleAddVerify" class="rounded-lg mb-1" color="#224a6d"><span
              class="white--text"> {{ $t("批准") }} </span></v-btn>
          <v-btn block :loading="addVerifiedLoading" :disabled="!addVerified" @click="handleAddConfirm"
            class="rounded-lg" color="#0483FF"><span class="white--text"> {{ $t("确定") }} </span></v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 移除流动性弹窗 -->
    <v-dialog v-model="removeLiquiShow" overlay-color="#202e8d" :width="dialogWidth">
      <v-card dark style="background: #202e8d">
        <v-container class="text-center font-weight-bold darkPink--text text-h6">
          <span> {{ $t("移除流动性") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="removeLiquiShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <v-container class="pl-3 pr-3 pb-5">
          <v-container class="mb-5" style=" ">
            <div class="d-flex align-center justify-space-between">
              <p class="mb-0 text-caption">
                {{ $t("持有数量") }} : {{ to4DecimalString(liquidityNumber) }}
              </p>
              <p class="mb-0 text-body-2">{{ $t("移除比例") }}</p>
            </div>
            <div class="d-flex align-center justify-space-between pt-9" style="height: 46px">
              <v-subheader class="pl-0 pr-0">0</v-subheader>
              <v-slider max="100" min="0" v-model="slider1" :thumb-size="18" thumb-label="always" track-color="#C3C5CB"
                color="#e359e1" thumb-color="#e359e1"></v-slider>
              <v-subheader class="pl-0 pr-0">100</v-subheader>
            </div>
            <div class="d-flex align-center justify-space-between pt-9" style="height: 44px">
              <v-text-field class="pt-0" v-model="inputLiquiRemove"></v-text-field>
              <v-subheader class="pl-0 pr-0" style="padding-bottom: 23px">
                <v-btn color="#4A2F4E" @click="handleClearMax"><span class="darkPink--text">{{ $t("最大") }}</span>
                </v-btn>
              </v-subheader>
            </div>
          </v-container>
          <v-btn block :disabled="
              !inputLiquiRemove || inputLiquiRemove == '0' || isNaN(Number(inputLiquiRemove))
            " :loading="clearVerifingLoading" v-show="!clearVerified" @click="handleClearVerify"
            class="rounded-lg mb-1" color="#4A2F4E"><span class="white--text"> {{ $t("批准") }}</span></v-btn>
          <v-btn block :loading="clearVerifiedLoading" :disabled="!clearVerified" @click="handleRemoveConfirm"
            class="rounded-lg" color="#4A2F4E"><span class="white--text"> {{ $t("确定") }}</span></v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 交易成功弹框 -->
    <v-dialog v-model="isShowConfirmDialog" overlay-color="#333846" :width="dialogWidth">
      <v-card style="background: #232429">
        <v-container class="text-center font-weight-bold textColor--text text-h6">{{ $t("交易成功") }}</v-container>
        <v-container class="pl-5 pr-5 pb-5">
          <v-btn width="100%" class="rounded-lg mb-3" large dark color="darkBlueBackground"
            @click="handleViewOnEarthscan">{{ $t(tradeSuccessTips) }}</v-btn>
          <v-btn width="100%" class="rounded-lg mb-3" large dark color="darkBlueBackground" @click="handSuccessConfirm">
            {{ $t("确定") }}</v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import helpers from '../helpers'
import eventBus from '../utils/eventBus'

import Divid from './Divid.vue' //分红币

import mineMixin from '../utils/mixin/mineMixin'//质押相关操作代码
import liquidityMixin from '../utils/mixin/liquidityMixin'//流动性相关操作代码

import { mapState, mapMutations } from 'vuex'
import { getNetworkConfig } from '../chainNetConfig.js'

export default {
  name: 'MineLiquidityDivid',
  components: {
    Divid
  },
  data() {
    return {
      settleToken: [],
      poolInfo: [],

      isLoadingData: false,
      currentItem: {},
      info: [],
      isStakeShow: false,
      isUnstakeShow: false,
      inputStake: '',
      inputUnstake: '',
      isStakeLoading: false,
      isStaked: false,
      isUnstakeLoading: false,
      isUnstaked: false,
      liquityChoose: '',
      confirmReceipt: null, // 交易完成后的收据Receipt
      isWrongNet: false,
      isStakedLoading: false,
      unstakeLoading: false,
      harvestLoading: false,

      // 流动性相关 start
      currentLang: 'zhHans',
      // isLoadingData: false,
      // currentItem: {},
      addLiquiShow: false,//添加流动性弹窗
      removeLiquiShow: false,//移除流动性弹窗
      inputLiquiAdd: '',
      inputLiquiRemove: '',
      addVerifingLoading: false,
      addVerifiedLoading: false,
      addVerified: false,
      clearVerifingLoading: false,
      clearVerifiedLoading: false,
      clearVerified: false,
      signature: {},
      walletInstalled: true,
      deadline: 0,
      settleBalance: 0,
      liquidityNumber: 0,
      slider1: 0,
      isShowConfirmDialog: false,
      // isWrongNet: false, // 是否是错误网络
      tradeSuccessTips: '在HECOINFO上查看',
      // confirmReceipt: null // 交易完成后的收据Receipt

      // 流动性相关 end
    }
  },
  computed: {
    dialogWidth: function () { //
      return this.isMobileBol ? '' : '520px'
    },
    ...mapState(['isMobileBol', 'defaultAccount', 'currentNetworkConfig', 'pendingStatus']),
    // 获取当前是否是英文
    type: () => localStorage.getItem('language')  === 'en'  
  },
  async mounted() {
      this.currentLang = localStorage.getItem('language')
      this.$nextTick(() => {
        this.isWrongNet = !this.currentNetworkConfig
      })
      
      const config = await getNetworkConfig()
      this.tradeSuccessTips = config.tradeSuccessTips

      const powerswapSettleTokenList = config.powerswapSettleTokenList
      this.settleToken = [...config.settleTokenList, ...powerswapSettleTokenList]

      // 'CNYT', 'CAKE', 'BUSD',不能添加质押，流动性，分红
      // 'CHARM'能质押，不能流动性，分红
      const removedToken = ['CNYT', 'CAKE', 'BUSD']
      this.settleToken = this.settleToken.filter(item=> !removedToken.includes(item.name))
      // charm置顶
      const charmIndex =  this.settleToken.findIndex(item=> {return item.name === 'CHARM'})
      if (charmIndex!==-1) {
        const charmItem = this.settleToken[charmIndex]
        this.settleToken.splice(charmIndex, 1)
        this.settleToken.unshift(charmItem)
      }

      this.getListData()
  },
  watch: {
    isStakeShow() {
      if (!this.isStakeShow) {
        this.inputStake = ''
      }
    },
    isUnstakeShow() {
      if (!this.isUnstakeShow) {
        this.inputUnstake = ''
      }
    },
    currentNetworkConfig: {
      handler(val) {
        this.isWrongNet = !val
      },
      deep: true
    },
    pendingStatus() {//pending状态
      if (!this.pendingStatus) {
        this.getSingleData()
      }
    },


    addLiquiShow () { // 关闭弹窗，清空数据
      this.inputLiquiAdd = ''
    },
    removeLiquiShow () { // 关闭弹窗，清空数据
      this.inputLiquiRemove = ''
      this.slider1 = 0
    },
    // currentNetworkConfig: {
    //   handler (val) {
    //     this.isWrongNet = !val
    //   },
    //   deep: true
    // },
    defaultAccount () {
      if (this.defaultAccount) {
        this.getListData()
      }
    },
    slider1 (val) {
      this.inputLiquiRemove = Math.floor((val / 100) * this.liquidityNumber * 10000) / 10000
    },
    // pendingStatus () {//pending状态
    //   if (!this.pendingStatus) {
    //     this.updateList()
    //   }
    // }
  },

  //注意：mixin的方法，属性命名避免相同
  mixins: [liquidityMixin, mineMixin ],

  methods: {
    ...mapMutations(['upPendingStatus']),
    charmOpen(item) {// 跳转到powerswap
      if (item.name.split('/').length > 1) {
        window.open('https://powerswap.charm.exchange/')
      }
    },
    async getListData() { // 获取列表数据
      if (!this.defaultAccount) {
        return
      }
      this.isLoadingData = true
      const allSettleToken = this.settleToken
      this.CHARMPrice = await helpers.getCHARMPrice()
      this.info = []

      this.poolInfo = await helpers.getPoolInfo()//池子里所有币种信息

      for (let i = 0; i < allSettleToken.length; i++) {
        const { liquidityTokenAddr, Bytes32, name, address, isPowerswap } = allSettleToken[i] 

        //获取当前币种的poolId（在池子里的index值）
        let pid = this.poolInfo.findIndex((item)=> {
          return item.liquidityTokenAddr.toLowerCase() === liquidityTokenAddr.toLowerCase()
        })

        const rewardPromise = helpers.getTokenRewards(pid, this.poolInfo)
        const tvlPromise = isPowerswap ? helpers.getTVLForPowerswap(name, liquidityTokenAddr)
          : helpers.getTVL(Bytes32, liquidityTokenAddr)

        const harvestPromise = helpers.getHarvest(pid, this.defaultAccount)
        const stakePromise = helpers.getStakeOfPool(pid, this.defaultAccount)
        const balancePromise = helpers.getBalanceOfPool(name, this.defaultAccount)


        const openTotalPromise = helpers.getTotalLiabilities(Bytes32)// 总开仓量
        const tokenLiquityPromise = helpers.getLiquilityBalance(name, this.defaultAccount)// 持有流动性数量
        const liquidityTotalPromise = helpers.getLiquidityTotalSupply(liquidityTokenAddr)// 流动性总量
        const settleTotalPromise = helpers.getSettleTokenTotalSupply(address, liquidityTokenAddr)// 结算币总量


        await Promise.all([rewardPromise, tvlPromise, harvestPromise, stakePromise, balancePromise,
         openTotalPromise, tokenLiquityPromise, liquidityTotalPromise, settleTotalPromise]).then((results) => {
          console.log('results----->', JSON.stringify(results))
          const reward = parseInt(results[0])
          const tvl = results[1]
          const harvest = helpers.to4DecimalString(results[2])
          const stake = helpers.to4DecimalString(results[3])
          const balance = helpers.to4DecimalString(results[4])
          const apr = ((reward * this.CHARMPrice * 365 / tvl) * 100).toFixed(4)


          const openTotal = results[5]
          const tokenLiquity = results[6]
          const liquidityTotal = results[7]
          const settleTotal = results[8]

          // 流动性份额占比
          const LiquidityShareAccountedFor = liquidityTotal === 0 || liquidityTotal === '0' ? 0 : (tokenLiquity / liquidityTotal * 100).toFixed(4)
          // 预估对应结算币数量
          let pusdTotal = 0
          if (name === 'HT' || name === 'BNB' || name === 'ETH') {
            pusdTotal = tokenLiquity < (settleTotal / 2) ? tokenLiquity : settleTotal / 2
          } else {
            pusdTotal = ((tokenLiquity * settleTotal) / liquidityTotal).toFixed(4)
            pusdTotal = pusdTotal < tokenLiquity ? pusdTotal : tokenLiquity// 取两者最小
          }

          this.info.push({
            id: pid,
            Bytes32,
            name,
            address,
            liquidityTokenAddr,
            logoName: allSettleToken[i].logoName,
            isPowerswap,
            loading: false,

            apr: apr === 'NaN' ? 0 : apr,
            tvl: tvl === 'NaN' ? 0 : tvl,
            reward,// 收益
            harvest,// 可获取

            stake, // 已质押
            balance, // 未质押

            liquidityTotal, // 流动性总量
            settleTotal, // 结算币总量
            liquidityNumber: tokenLiquity, // 持有流动性数量
            LiquidityShareAccountedFor, // 流动性份额占比
            pusdTotal, // 预估对应结算币数量
            openTotal, // 总开仓量

          })

        }).catch(function (err) {
          console.log(err)
        })
      }

      this.isLoadingData = false
    },
   
    async getSingleData() { /* 更新列表单个数据 */
      this.inputStake = ''
      this.inputUnstake = ''
      this.isLoadingData = true
      const id = this.currentItem.id
      const name = this.currentItem.name

      const index = this.info.findIndex(e => e.name === name)
      const settleTokenInfo = this.settleToken.find(e => e.name === name)

      const { liquidityTokenAddr, Bytes32, address,isPowerswap } = settleTokenInfo

      const rewardPromise = helpers.getTokenRewards(id, this.poolInfo)
      const tvlPromise = isPowerswap ? helpers.getTVLForPowerswap(name, liquidityTokenAddr)
        : helpers.getTVL(Bytes32, liquidityTokenAddr)

      const harvestPromise = helpers.getHarvest(id, this.defaultAccount)
      const stakePromise = helpers.getStakeOfPool(id, this.defaultAccount)
      const balancePromise = helpers.getBalanceOfPool(name, this.defaultAccount)


      const openTotalPromise = helpers.getTotalLiabilities(Bytes32)// 总开仓量
      const tokenLiquityPromise = helpers.getLiquilityBalance(name, this.defaultAccount)// 持有流动性数量
      const liquidityTotalPromise = helpers.getLiquidityTotalSupply(liquidityTokenAddr)// 流动性总量
      const settleTotalPromise = helpers.getSettleTokenTotalSupply(address, liquidityTokenAddr)// 结算币总量


      await Promise.all([rewardPromise, tvlPromise, harvestPromise, stakePromise, balancePromise,
      openTotalPromise, tokenLiquityPromise, liquidityTotalPromise, settleTotalPromise]).then((results) => {
        console.log('results----->', JSON.stringify(results))
        const reward = parseInt(results[0])
        const tvl = results[1]
        const harvest = helpers.to4DecimalString(results[2])
        const stake = helpers.to4DecimalString(results[3])
        const balance = helpers.to4DecimalString(results[4])
        const apr = ((reward * this.CHARMPrice * 365 / tvl) * 100).toFixed(4)


        const openTotal = results[5]
        const tokenLiquity = results[6]
        const liquidityTotal = results[7]
        const settleTotal = results[8]

        // 流动性份额占比
        const LiquidityShareAccountedFor = liquidityTotal === 0 || liquidityTotal === '0' ? 0 : (tokenLiquity / liquidityTotal * 100).toFixed(4)
        // 预估对应结算币数量
        let pusdTotal = 0
        if (name === 'HT' || name === 'BNB' || name === 'ETH') {
          pusdTotal = tokenLiquity < (settleTotal / 2) ? tokenLiquity : settleTotal / 2
        } else {
          pusdTotal = ((tokenLiquity * settleTotal) / liquidityTotal).toFixed(4)
          pusdTotal = pusdTotal < tokenLiquity ? pusdTotal : tokenLiquity// 取两者最小
        }

        const info = {
          id: id,
          address,
          liquidityTokenAddr,
          logoName: settleTokenInfo.logoName,
          name,
          isPowerswap,
          loading: false,

          apr: apr === 'NaN' ? 0 : apr,
          tvl: tvl === 'NaN' ? 0 : tvl,
          reward,
          harvest,

          stake, // 已质押
          balance,

          liquidityTotal, // 流动性总量
          settleTotal, // 结算币总量
          liquidityNumber: tokenLiquity, // 持有流动性数量
          LiquidityShareAccountedFor, // 流动性份额占比
          pusdTotal, // 预估对应结算币数量
          openTotal, // 总开仓量
        }
        this.$set(this.info, index, info)
      }).catch(function (err) {
        console.log(err)
      })
    },
    async handleViewOnEarthscan() { // 查看交易成功信息
      const transactionHash = this.confirmReceipt ? this.confirmReceipt.transactionHash : ''
      const config = await getNetworkConfig()
      const url = `${config.netUrl}/tx/${transactionHash}`
      window.open(url)
    },
    handleUnlockWallet() {
      eventBus.$emit('handleUnlockWallet')
    },
  }
}
</script>
<style lang="scss" scoped>
.light-divider {
  color: #707070;
  border-color: #707070 !important;
}

.unlock {
  padding-top: 20px !important;
}

.unlock-button {
  margin-top: 20px !important;
}

.mine-container {
  width: 84%;
  min-height: 730px;
  overflow: auto;
  margin: 0 auto;
  margin-top: 50px;

  &.mobie {
    width: 100%;
    margin-top: 20px;
  }

  .unlock-wallet-box {
    min-height: calc(100vh - 300px);
    position: relative;

    >button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .card_content {
    margin: 30px 15px;
    background-image: url("../assets/card-bg.png");
    background-size: 90%;

    .trade-type {
      margin: 20px 0;
      height: 23px;
      font-size: 18px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: center;
      color: #283f91;

      &.charm-trade {
        color: #283f91;
        cursor: pointer;
        opacity: 1;
        text-decoration: underline;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .label-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
      font-weight: 400;
      text-align: left;
      color: #283f91;
    }

    .value-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #283f91;
      margin: 5px 0;
    }

    .icon_image_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: -30px;
      width: 100%;
    }
  }

  .card_footer {
    display: flex;
    justify-content: right;
    padding: 0 10px 20px;

    .text1 {
      font-size: 12px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #ffffff;
    }
  }

  

  .title_text {
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .loading {
    text-align: center;
  }


  .new-list-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1300px;
    padding: 4px 10px;
    color: #fff;
    border-bottom: 2px solid #112443;
    .content-left{
      width: 200px;
      display: flex;
      align-items: center;
      .trade-type {
        margin: 20px 0;
        height: 23px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #76D3B9;
        width: 130px;
        &.charm-trade {
          color: #76D3B9;
          cursor: pointer;
          opacity: 1;
          text-decoration: underline;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .content-right{
      // width: 1050px;
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      .line-active{
        justify-content: unset!important;
      }
      .mine-line{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 8px 0;
        .item-info{
          background:#3D1956;
          display: flex;
          width: 870px;
          height: 48px;
          padding: 0 15px;
          align-items: center;
          >div{
            width: 210px;
            >span{
              &:nth-child(1){
                color: #c3c5cb ;
              }
            }
            // margin-right: 10px;
          }
        }
        .operation{
          display: flex;
          align-items: center;
          justify-content: center;
          // min-width: 220px;
          width: 220px;
        }
      }
      .liquidity-line{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 8px 0;
        .item-info{
          background: #193156;
          display: flex;
          width: 870px;
          height: 48px;
          padding: 0 15px;
          align-items: center;
          >div{
            width: 210px;
            >span{
              &:nth-child(1){
                color: #c3c5cb ;
              }
            }
          }
        }
        .operation{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 220px;

        }

        .operation-active{
          min-width: 220px;
          margin-left: 8px;
          width: unset;
        }
      }
    }
  }
}

.tag-ifno {
     display: flex;
      align-items: center;

      .lumps {
        position: relative;
        height: 25px;

        span:first-child {
          border-left: 14px solid #2C39EC;
          max-height: 25px;
          padding: 3px 0;
        }

        span:last-child {
          border-left: 14px solid #FFFFFF;
          position: absolute;
          height: 20px;
          top: 14px;
          left: 8px;
       }
        }
  }


</style>
