import helpers from '../../helpers'
import { getNetworkConfig } from '../../chainNetConfig.js'
export default {
  data() {
    return {
    };
  },
  methods: {
    /* 质押相关操作 start */
    handleStakeMax() {
      this.inputStake = Math.floor(this.currentItem.balance * 10000) / 10000
    },
    handleUnstakeMax() {
      this.inputUnstake = Math.floor(this.currentItem.stake * 10000) / 10000
    },

    // 验证抵押
    async handleStakeVerify() {
      this.isStakeLoading = true
      console.log(this.currentItem)
      const config = await getNetworkConfig()
      const addr = config.liquidityTokenAddress[this.currentItem.name]
      helpers.approveDeposit(addr, 1000000000000, this.defaultAccount,
        (error) => {
          if (error != null) {
            console.log(error)
            this.isStakeLoading = false
          }
        }, () => {
          this.isStaked = true
          this.isStakeLoading = false
        })
    },
    /// 验证取消抵押
    handleUnstakeVerify() {
      this.isUnstakeLoading = true
      helpers.approveWithdraw(1000000000000, this.defaultAccount,
        (error) => {
          if (error != null) {
            console.log(error)
            this.isUnstakeLoading = false
          }
        }, () => {
          this.isUnstaked = true
          this.isUnstakeLoading = false
        })
    },

    handleStakeButton(item) {
      console.log(item)
      this.currentItem = item
      this.isStakeShow = true
    },
    handleUnstakeButton(item) {
      console.log(item)
      this.currentItem = item
      this.isUnstakeShow = true
    },
    handleStake() { // 确认质押
      this.isStakedLoading = true
      console.log(this.currentItem)
      console.log(this.inputStake)
      console.log(this.defaultAccount)
      let id = 0
      const poolInfo = this.poolInfo
      for (let i = 0; i < poolInfo.length; i++) {
        if (poolInfo[i].liquidityTokenAddr === this.currentItem.liquidityTokenAddr) {
          id = i
        }
      }
      helpers.deposit(id, this.inputStake, this.defaultAccount, (err) => {
        if (err != null) {
          console.log(err)
          this.isStaked = false
          this.isStakeShow = false
          this.isStakedLoading = false
        }
      }, () => {
        this.upPendingStatus(true)
        this.isStaked = false
        this.isStakeShow = false
        this.isStakedLoading = false
      })
    },
    handleUnstake() { // 确认取消质押
      this.unstakeLoading = true
      helpers.withdrawAndHarvest(this.currentItem.id, this.inputUnstake, this.defaultAccount, (err) => {
        if (err != null) {
          console.log(err)
          this.isUnstaked = false
          this.isUnstakeShow = false
          this.unstakeLoading = false
        }
      }, () => {
        this.upPendingStatus(true)
        this.isUnstaked = false
        this.isUnstakeShow = false
        this.unstakeLoading = false
      })
    },
    handleHarvest(item) { // 获取
      item.loading = true
      this.currentItem = item
      helpers.harvest(item.id, this.defaultAccount, (err) => {
        if (err != null) {
          console.log(err)
          item.loading = false
          // this.getSingleData()
        }
      }, () => {
        item.loading = false
        this.getSingleData()
      })
    },
     /* 质押相关操作 end */
 
  }
};
