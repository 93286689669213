import helpers from '../../helpers'

export default {
  data() {
    return {
    };
  },
  methods: {

     /* 流动性相关操作 start */
     async handleAdd (item) {
      this.currentItem = item
      if (this.currentItem.name === 'HT' || this.currentItem.name === 'BNB' || this.currentItem.name === 'ETH') {
        await helpers.getETHBalance(this.defaultAccount, (balance) => {
          this.settleBalance = String(balance).replace(/^(.*\..{4}).*$/, '$1')
        })
      } else {
        this.settleBalance = await helpers.getBalance(item.address, this.defaultAccount)
      }
      this.addLiquiShow = true
    },
    // handleUnlockWallet () {
    //   eventBus.$emit('handleUnlockWallet')
    // },
    handleAddMax () {
      this.inputLiquiAdd = Math.floor(this.settleBalance * 10000) / 10000
    },
    handleClearMax () {
      this.inputLiquiRemove = Math.floor(this.liquidityNumber * 10000) / 10000
    },
    /// 清除流动
    async handleClear (item) {
      this.currentItem = item
      this.liquidityNumber = await helpers.getLiquilityBalance(item.name, this.defaultAccount)
      this.removeLiquiShow = true
    },
    /// 验证添加流动
    handleAddVerify () {
      this.addVerifingLoading = true
      const Bytes32 = this.currentItem.Bytes32

      helpers.approveToken(Bytes32, 1000000000000, this.defaultAccount,
        (error) => {
          if (error != null) {
            console.log(error)
            this.addVerifingLoading = false
          }
        }, () => {
          this.addVerified = true
          this.addVerifingLoading = false
        })
    },
    /// 验证移除流动
    handleClearVerify () {
      this.clearVerifingLoading = true
      helpers.getLiquiditySignature(this.currentItem.name, this.inputLiquiRemove, this.defaultAccount,
        (error, permitData, deadline) => {
          if (error != null) {
            console.log(error)
            this.clearVerifingLoading = false
          } else {
            this.signature = permitData
            this.deadline = deadline
            this.clearVerified = true
            this.clearVerifingLoading = false
          }
        })
    },
    // async handleViewOnEarthscan () {
    //   const transactionHash = this.confirmReceipt ? this.confirmReceipt : ''
    //   const config = await getNetworkConfig()
    //   const url = `${config.netUrl}/tx/${transactionHash}`
    //   window.open(url)
    // },
    handSuccessConfirm () {
      this.isShowConfirmDialog = false
    },
    async handleAddConfirm () {
      this.addVerifiedLoading = true
      if (this.currentItem.name === 'HT' || this.currentItem.name === 'BNB' || this.currentItem.name === 'ETH') {
        helpers.addLiquidityETH(this.inputLiquiAdd, this.defaultAccount,
          this.handleTXCallBack, this.handleAddTXConfirmCallBack)
      } else {
        const settleToken = await helpers.getSettleTokenListByConfig()
        let Bytes32 = settleToken.find((i) => { return i.name === this.currentItem.name }).Bytes32
        helpers.addLiquidity(Bytes32, this.inputLiquiAdd, this.defaultAccount,
          this.handleTXCallBack, this.handleAddTXConfirmCallBack)
      }
    },
    handleRemoveConfirm () {
      this.clearVerifiedLoading = true
      if (this.currentItem.name === 'HT' || this.currentItem.name === 'BNB' || this.currentItem.name === 'ETH') {
        helpers.removeLiquidityETHWithPermit(this.inputLiquiRemove, this.defaultAccount, this.signature, this.deadline,
          this.handleTXCallBack, this.handleClearTXConfirmCallBack)
      } else {
        helpers.removeLiquidityWithPermit(this.currentItem.Bytes32, this.inputLiquiRemove, this.defaultAccount,
          this.signature, this.deadline, this.handleTXCallBack, this.handleClearTXConfirmCallBack)
      }
    },
    handleAddTXConfirmCallBack (receipt) {
      this.upPendingStatus(true)
      this.addVerifiedLoading = false
      this.addVerified = false

      this.addLiquiShow = false
      this.isShowConfirmDialog = true
      this.confirmReceipt = receipt
    },
    handleTXCallBack (error) {
      if (error != null) {
        console.log(error)
        this.addVerifiedLoading = false
      }
    },
    handleClearTXConfirmCallBack (receipt) {
      this.upPendingStatus(true)
      this.clearVerifiedLoading = false
      this.clearVerified = false

      this.removeLiquiShow = false
      this.isShowConfirmDialog = true
      this.confirmReceipt = receipt
    },
    to4DecimalString (number) {
      return String(Math.floor(parseFloat(number) * 10000) / 10000)
    }
     /*  流动性相关操作 end */
 
  }
};
