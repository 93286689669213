<template>
  <!-- 分红  -->
  <div class="pl-3 pr-3 divid-container" :class="{'mobie':isMobileBol}">
    <!-- 已解锁钱包 -->
    <v-container>
      <!-- 顶部header -->
      <div v-if="isLoadingData">
        <!-- <v-progress-linear indeterminate color="darkBlue darken-2"></v-progress-linear> -->
        <!-- <h3 class="white--text loading">{{ $t("加载中") }}.......</h3> -->
      </div>
      <!-- 列表 -->
      <v-container fluid v-else>
        <v-row style="justify-content: center;">
          <h4  class="tag-ifno" style="font-size: 22px;color:#fff;width: 1300px;margin: 0 auto;margin-bottom:20px;padding-left: 8px;">
                <div class="lumps"><span></span><span></span></div>
                 <span style=" padding-left: 20px;">{{ $t('平台分红') }}</span>
                </h4>

          <v-progress-linear v-if="dividList.length > 1 && dividList.length < settleToken.length" indeterminate
            color="darkBlue darken-2"></v-progress-linear>
          <template  v-if="!isMobileBol" >
            <div class="new-list-content"  v-for="(item,index) in dividList" :key="index" >
                <div class="content-left">
                   <div class="icon_image_content">
                      <v-img style="border-radius: 50%;" :src="require(`../assets/currencyType/${item.name}.png`)"
                        max-height="50" max-width="50">
                      </v-img>

                    </div>
                    <div class="trade-type"> {{ item.name }}</div>
                </div>

                <div class="content-right">

                  <div class="mine-line">
                    <div class="item-info">
                      <div>
                        <span>{{ $t("分红币数量") }}</span>
                        <span>  {{ item.stakeDivid }}</span>
                      </div>
                      <div>
                        <span>{{ $t("分红币总量") }}</span>
                        <span> {{ item.totalDividendAmount }}</span>
                      </div>
                    </div>
                    <div class="operation">
                        <v-btn style="width:300px;color:#fff;background: #2C39EC;border-radius: 4px;" small
                        @click="handleStakeButton(item)"> <span class="text1">{{ $t('将CHARM置换成分红币') }}</span>
                        </v-btn>
                    </div>
                  </div>
                  <div class="liquidity-line">
                    <div class="item-info">
                      <div>
                        <span>{{ $t("分红币占比") }}</span>
                        <span>{{ item.stakePercent }}</span>
                      </div>
                      <div>
                        <span>{{ $t('可获取利润') }}</span>
                        <span> {{ item.claimableProfits }}</span>
                      </div>
                    </div>
                    <div class="operation">
                      <v-btn v-if="item.claimableProfits < 0.0001" disabled 
                        style="opacity: 0.6 !important; width:300px;background:transparent; border: 1px solid#CA585A;color:#CA585A !important;border-radius: 4px;" small>
                        {{ $t('销毁分红币获取分红') }}
                      </v-btn>
                      <v-btn v-else @click="handleUnstakeButton(item)"
                        style="width:180px;background:transparent; border: 1px solid#CA585A;color:#CA585A;border-radius: 4px;" small>
                        <span class="text1">
                          {{ $t('销毁分红币获取分红') }}
                        </span>
                      </v-btn>
                    </div>
                  </div>

                </div>
          </div>
          </template>
          <!-- 移动端展示 -->
          <template  v-else>
            <v-card class="card_content" v-for="(item,index) in dividList" :key="index"
              :width="isMobileBol||currentLang === 'en'?290:250">

              <v-list dense subheader style="background: transparent;">
                <div class="icon_image_content">
                  <v-img style="border-radius: 50%;" :src="require(`../assets/currencyType/${item.name}.png`)"
                    max-height="50" max-width="50">
                  </v-img>

                </div>
                <div class="trade-type"> {{ item.name }}</div>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{ $t("分红币数量") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ item.stakeDivid }}
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{ $t("分红币占比") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ item.stakePercent }}
                  </v-list-item-icon>
                </v-list-item>
                <v-container class="card_footer">
                  <v-btn @click="handleStakeButton(item)"
                    style="opacity: 0.9;background: #1e1e4b;border-radius: 16px;margin-right:10px" small>
                    <span class="text1">
                      {{ $t("兑换") }}
                    </span>
                  </v-btn>

                  <v-btn v-if="item.claimableProfits < 0.0001" disabled style="color:#fff;border-radius: 16px;" small>
                    {{ $t("销毁") }}
                  </v-btn>
                  <v-btn v-else @click="handleUnstakeButton(item)"
                    style="opacity: 0.9;background: #c15ec0;border-radius: 16px;" small>
                    <span class="text1">
                      {{ $t("销毁") }}
                    </span>
                  </v-btn>
                </v-container>

                <v-divider style="max-width: 88%;margin: 0 auto;"></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{ $t("分红币总量") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ item.totalDividendAmount }}
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="label-text">{{$t('池子可获取利润')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="value-text">
                    {{ item.claimableProfits }}
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card>
          </template>
        </v-row>
      </v-container>
    </v-container>

    <!-- 兑换弹窗 -->
    <v-dialog v-model="isStakeShow" overlay-color="#202e8d" :width="isMobileBol ? '' : '520px'">
      <v-card dark style="background: #202e8d">
        <v-container class="text-center font-weight-bold textColor--text text-h6">
          <span>{{ $t("兑换") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isStakeShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <v-container class="pl-3 pr-3 pb-5">
          <v-container class="mb-5" style=" ">
            <div class="d-flex align-center justify-space-between">
              <p class="mb-0 text-caption">
                CHARM:
                {{ to4DecimalString(this.balanceCHARM) }}
              </p>
            </div>
            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between pt-9" style="height: 44px">
              <v-text-field class="pt-0" v-model="inputStake"></v-text-field>
              <v-subheader class="pl-0 pr-0" style="padding-bottom: 23px">
                <v-btn color="#424D5F" @click="handleStakeMax"><span class="bearButtonTrade--text">{{
                    $t("最大")
                  }}</span></v-btn>
              </v-subheader>
            </div>
          </v-container>
          <v-btn block :disabled="
              !inputStake || inputStake == '0' || isNaN(Number(inputStake))
            " :loading="isStakeLoading" v-show="!isStaked" @click="handleStakeVerify" class="rounded-lg mb-1"
            color="#224a6d"><span class="white--text"> {{ $t("批准") }}</span></v-btn>
          <v-btn block :loading="isStakedLoading" :disabled="!isStaked" @click="addDividen" class="rounded-lg"
            color="#224a6d"><span class="white--text"> {{ $t("确定") }}</span></v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 销毁 -->
    <v-dialog v-model="isUnstakeShow" overlay-color="#202e8d" :width="isMobileBol ? '' : '520px'">
      <v-card dark style="background: #202e8d">
        <v-container class="text-center font-weight-bold darkPink--text text-h6">
          <span> {{ $t("销毁") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isUnstakeShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <v-container cl="pl-3 pr-3 pb-5">
          <v-container class="mb-5" style=" ">
            <div class="d-flex align-center justify-space-between">
              <p class="mb-0 text-caption">
                {{ $t("分红币数量") }}:
                {{ to4DecimalString(this.currentItem.stakeDivid) }}
              </p>
            </div>
            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between pt-9" style="height: 44px">
              <v-text-field class="pt-0" v-model="inputUnstake"></v-text-field>
              <v-subheader class="pl-0 pr-0" style="padding-bottom: 23px">
                <v-btn color="#4A2F4E" @click="handleUnstakeMax"><span class="darkPink--text"> {{ $t("最大") }}</span>
                </v-btn>
              </v-subheader>
            </div>
          </v-container>
          <v-btn block :disabled="
              !inputUnstake ||
              inputUnstake == '0' ||
              isNaN(Number(inputUnstake))
            " :loading="isUnstakeLoading" v-show="!isUnstaked" @click="burnDividenVerify" class="rounded-lg mb-1"
            color="#4A2F4E"><span class="white--text"> {{ $t("批准") }}</span></v-btn>
          <v-btn block :loading="unstakeLoading" :disabled="!isUnstaked" @click="burnDividen" class="rounded-lg"
            color="#4A2F4E"><span class="white--text"> {{ $t("确定") }}</span></v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import helpers from '../helpers'
import eventBus from '../utils/eventBus'
import { mapState, mapMutations } from 'vuex'
import { getNetworkConfig } from '../chainNetConfig.js'

export default {
  name: 'Divid2',
  components: {
  },
  data () {
    return {
      currentLang: 'zhHans',
      warnningShow: false,
      settleToken: [],

      loginDialog: false,
      isLoadingData: false,
      currentItem: {},

      dividList: [], // 分红币 list
      isStakeShow: false,
      isUnstakeShow: false,
      inputStake: '',
      inputUnstake: '',
      isStakeLoading: false,
      isStaked: false,
      isUnstakeLoading: false,
      isUnstaked: false,
      // walletInstalled: true,
      confirmReceipt: null, // 交易完成后的收据Receipt
      totalDividendAmount: 0,
      balanceCHARM: '',
      isWrongNet: false,
      isStakedLoading: false,
      unstakeLoading: false
    }
  },
  computed: {
    ...mapState(['isMobileBol', 'defaultAccount', 'currentNetworkConfig', 'pendingStatus'])
  },
  mounted () {
    this.currentLang = localStorage.getItem('language')
    this.$nextTick(() => {
      this.isWrongNet = !this.currentNetworkConfig
      setTimeout(async () => {
        this.settleToken = await helpers.getSettleTokenListByConfig()
        // CHAR CNYT不能兑换分红币
        const removedToken = ['CHARM','CNYT', 'CAKE', 'BUSD']
        this.settleToken = this.settleToken.filter(item=> !removedToken.includes(item.name))
        this.getDividList()
      }, 300)
    })
  },
  watch: {
    isStakeShow () {
      if (!this.isStakeShow) {
        this.inputStake = ''
      }
    },
    isUnstakeShow () {
      if (!this.isUnstakeShow) {
        this.inputUnstake = ''
      }
    },
    currentNetworkConfig: {
      handler (val) {
        this.isWrongNet = !val
      },
      deep: true
    },
    pendingStatus () {//pending状态
      if (!this.pendingStatus) {
        this.updateDividData()
        this.updataBalanceCHARM()
      }
    }
  },
  methods: {
    ...mapMutations(['upPendingStatus']),

    async getDividList () { // 获取列表数据
      if (!this.defaultAccount) {
        return
      }
      this.isLoadingData = true
      this.dividList = []

      const config = await getNetworkConfig()
      const CHARMsettle = config.settleTokenList.find((i) => { return (i.name === 'CHARM')})
      this.balanceCHARM = helpers.to4DecimalString(await helpers.getBalance(CHARMsettle.address, this.defaultAccount))

      const aa = new Date().getTime()
      for (let i = 0; i < this.settleToken.length; i++) {
        const dividendToken = config.dividendSettleTokenList.find((item) => { return item.settleTokenAddr === this.settleToken[i].address })
        const settleTokenKey = config.settleTokenList.find((item) => { return dividendToken.settleTokenAddr === item.address }).Bytes32

        const stakeDivid = helpers.getStakeOfDivid(dividendToken.address, this.defaultAccount)// 分红币数量
        const totalDividendAmount = helpers.getTotalOfDivid(dividendToken.address)// 分红币总量
        const claimableProfits = helpers.getClaimableProfitslOfDivid(settleTokenKey)// 池子可获取利润

        await Promise.all([stakeDivid, totalDividendAmount, claimableProfits]).then((results) => {
          const stakeDivid = results[0]
          const totalDividendAmount = results[1]
          const claimableProfits = results[2]

          this.dividList.push({
            name: this.settleToken[i].name,
            stakeDivid: (stakeDivid / 1000000000000000).toFixed(4), // 分红币数量
            totalDividendAmount: (totalDividendAmount / 1000000000000000).toFixed(4), // 分红币总量
            claimableProfits: (claimableProfits / Math.pow(10, 18)).toFixed(4), // 池子可获取利润
            stakePercent: ((stakeDivid / totalDividendAmount) * 100).toFixed(4) + '%', // 分红币占比
            address: this.settleToken[i].address,
            loading: false
          })
        }).catch((err) => { console.log(err) })
      }
      const bb = new Date().getTime()
      console.log(bb - aa, 'bb-aa')
      this.isLoadingData = false
    },
    /* 更新列表单个数据 */
    async updateDividData () {
      const config = await getNetworkConfig()
      this.inputStake = ''
      this.inputUnstake = ''
      // this.isLoadingData = true
      const index = this.dividList.findIndex(e => e.name === this.currentItem.name)
      const settleTokenInfo = this.settleToken.find(e => e.name === this.currentItem.name)

      const dividendToken = config.dividendSettleTokenList.find((item) => { return item.settleTokenAddr === settleTokenInfo.address })
      const settleTokenKey = config.settleTokenList.find((item) => { return dividendToken.settleTokenAddr === item.address }).Bytes32

      const stakeDivid = helpers.getStakeOfDivid(dividendToken.address, this.defaultAccount)// 分红币数量
      const totalDividendAmount = helpers.getTotalOfDivid(dividendToken.address)// 分红币总量
      const claimableProfits = helpers.getClaimableProfitslOfDivid(settleTokenKey)// 池子可获取利润

      await Promise.all([stakeDivid, totalDividendAmount, claimableProfits]).then((results) => {
        const stakeDivid = results[0]
        const totalDividendAmount = results[1]
        const claimableProfits = results[2]

        const obj = {
          name: settleTokenInfo.name,
          stakeDivid: (stakeDivid / 1000000000000000).toFixed(4), // 分红币数量
          totalDividendAmount: (totalDividendAmount / 1000000000000000).toFixed(4), // 分红币总量
          claimableProfits: (claimableProfits / Math.pow(10, 18)).toFixed(4), // 池子可获取利润
          stakePercent: ((stakeDivid / totalDividendAmount) * 100).toFixed(4) + '%', // 分红币占比
          address: settleTokenInfo.address,
          loading: false
        }
        this.$set(this.dividList, index, obj)
      }).catch((err) => { console.log(err) })
      // this.isLoadingData = false
    },
    handleCancel () {
      this.loginDialog = false
    },
    handleUnlockWallet () {
      eventBus.$emit('handleUnlockWallet')
      // this.loginDialog = true
    },
    handleStakeMax () {
      this.inputStake = Math.floor(this.balanceCHARM * 10000) / 10000
    },
    handleUnstakeMax () {
      this.inputUnstake = Math.floor(this.currentItem.stakeDivid * 10000) / 10000
    },

    /// 兑换分红-- 验证
    async handleStakeVerify () {
      if (!this.inputStake) {
        this.warnningShow = true
        return
      }
      this.isStakeLoading = true
      const config = await getNetworkConfig()
      const CHARMsettle = config.settleTokenList.find((i) => { return (i.name === 'CHARM') })
      const address = CHARMsettle.address
      helpers.getDividendTokenSignature(address, this.inputStake, this.defaultAccount,
        (error, permitData, deadline) => {
          if (error != null) {
            this.isStakeLoading = false
          } else {
            this.permitData = permitData
            this.deadline = deadline
            this.isStaked = true
            this.isStakeLoading = false
          }
        })
    },
    /// 销毁分红-- 验证
    async burnDividenVerify () {
      this.isUnstakeLoading = true
      const config = await getNetworkConfig()
      const address = config.dividendSettleTokenList.find((i) => { return i.settleTokenAddr === this.currentItem.address }).address
      helpers.getDividendTokenSignature(address, this.inputUnstake * 1000000000000000, this.defaultAccount,
        (error, permitData, deadline) => {
          if (error != null) {
            this.isUnstakeLoading = false
          } else {
            this.permitData = permitData
            this.deadline = deadline
            this.isUnstaked = true
            this.isUnstakeLoading = false
          }
        }, false)
    },
    async handleViewOnEarthscan () {
      const transactionHash = this.confirmReceipt ? this.confirmReceipt.transactionHash : ''
      const config = await getNetworkConfig()
      const url = `${config.netUrl}/tx/${transactionHash}`
      window.open(url)
    },

    handleStakeButton (item) {
      console.log(item)
      this.currentItem = item
      this.isStakeShow = true
    },
    handleUnstakeButton (item) {
      console.log(item)
      this.currentItem = item
      this.isUnstakeShow = true
    },
    async addDividen () { // 兑换分红币
      this.isStakedLoading = true
      const config = await getNetworkConfig()
      const Bytes32 = config.settleTokenList.find((i) => { return i.address === this.currentItem.address }).Bytes32
      helpers.buyDividendTokenWithPermit(Bytes32, this.inputStake, this.defaultAccount, this.deadline, this.permitData, (err) => {
        if (err != null) {
          console.log(err)
          this.isStaked = false
          this.isStakeShow = false
          this.isStakedLoading = false
        }
      }, (receipt) => {
        this.upPendingStatus(true)
        console.log(receipt, ' receipt')
        this.isStaked = false
        this.isStakeShow = false
        this.isStakedLoading = false
        // this.updateDividData()
        // this.updataBalanceCHARM()
      })
    },
    async updataBalanceCHARM () {
      const config = await getNetworkConfig()
      const CHARMsettle = config.settleTokenList.find((i) => { return (i.name === 'CHARM') })
      this.balanceCHARM = helpers.to4DecimalString(await helpers.getBalance(CHARMsettle.address, this.defaultAccount))
    },
    async burnDividen () { // 销毁分红币
      this.unstakeLoading = true
      const config = await getNetworkConfig()
      const Bytes32 = config.settleTokenList.find((i) => { return i.address === this.currentItem.address }).Bytes32
      if (!this.inputUnstake) {
        return
      }
      helpers.sellDividendTokenWithPermit(Bytes32, this.inputUnstake * 1000000000000000, this.defaultAccount,
        this.deadline, this.permitData, (err) => {
          if (err != null) {
            console.log(err)
            this.isUnstaked = false
            this.isUnstakeShow = false
            this.unstakeLoading = false
          }
        }, () => {
          this.upPendingStatus(true)
          this.isUnstaked = false
          this.isUnstakeShow = false
          this.unstakeLoading = false
          // this.updateDividData()
          // this.updataBalanceCHARM()
        })
    },
    to4DecimalString (number) {
      return String(Math.floor(parseFloat(number) * 10000) / 10000)
    }
  }
}
</script>
<style lang="scss" scoped>
.light-divider {
  color: #707070;
  border-color: #707070 !important;
}

.unlock {
  padding-top: 20px !important;
}

.unlock-button {
  margin-top: 20px !important;
}
.divid-container {
  // width: 84%;
  min-height: 730px;
  overflow: auto;
  margin: 0 auto;
  margin-top: 50px;
  &.mobie {
    width: 100%;
    margin-top: 20px;
  }
  .unlock-wallet-box {
    min-height: calc(100vh - 300px);
    position: relative;
    > button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .card_content {
    margin: 30px 15px;
    background-image: url("../assets/card-bg.png");
    background-size: 80%;
    .trade-type {
      margin: 20px 0;
      height: 23px;
      font-size: 18px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: center;
      color: #283f91;
    }
    .label-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
      font-weight: 400;
      text-align: left;
      color: #283f91;
    }
    .value-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #283f91;
    }
    .icon_image_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: -30px;
      width: 100%;
    }
  }

  .card_footer {
    display: flex;
    justify-content: right;
    padding: 10px;
    .text1 {
      font-size: 12px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #ffffff;
    }
  }
  .title_text {
    color: #fff;
    font-size: 180% !important;
    margin-bottom: 40px;
  }
  .loading {
    text-align: center;
  }


  .new-list-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1300px;
    padding: 4px 10px;
    color: #fff;
    border-bottom: 2px solid #112443;
    .content-left{
      width: 200px;
      display: flex;
      align-items: center;
      .trade-type {
        margin: 20px 0;
        height: 23px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #76D3B9;
        width: 130px;
        &.charm-trade {
          color: #76D3B9;
          cursor: pointer;
          opacity: 1;
          text-decoration: underline;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .content-right{
      // width: 1050px;
      display: flex;
      flex-direction: column;
      .mine-line{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 5px 0;
        .item-info{
          display: flex;
          width: 670px;
          height: 30px;
          padding: 0;
          align-items: center;
          >div{
            width: 350px;
            >span{
              &:nth-child(1){
                color: #c3c5cb ;
              }
            }
            // margin-right: 10px;
          }
        }
        .operation{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 420px;
        }
      }
      .liquidity-line{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 5px 0;
        .item-info{
          display: flex;
          width: 670px;
          height: 30px;
          padding: 0;
          align-items: center;
          >div{
            width: 350px;
            >span{
              &:nth-child(1){
                color: #c3c5cb ;
              }
            }
          }
        }
        .operation{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 420px;
        }
      }
    }
  }
}

.tag-ifno {
  display: flex;
      align-items: center;

      .lumps {
        position: relative;
        height: 25px;

        span:first-child {
          border-left: 14px solid #2C39EC;
          max-height: 25px;
          padding: 3px 0;
        }

        span:last-child {
            border-left: 14px solid #FFFFFF;
            position: absolute;
            height: 25px;
            height: 20px;
            top: 14px;
            left: 8px;
        }
      }

      h4 {
        padding: 0 0 0 30px;
        font-size: 18px;
      }
    }
</style>

